import React from "react"
import { useBetween } from 'use-between'
import { Link, useLocation } from "react-router-dom"

// MUI
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

// MUI - ICONS
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

// OTHER
// import pages from '../pages/pages'

const useDrawerState = () => {
    const [open, setOpen] = React.useState(false)
    const toggleDrawer = () => {        
        setOpen(!open)
    }
    return {
        open, toggleDrawer
    }
}

const useSharedDrawerState = () => useBetween(useDrawerState);

const drawerWidth = 230;

const DrawerStyled = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
)
  

function MenuItems(path, settings) {
    const location = useLocation();

    const activeRoute = (path, currentPath) => {        
        return path === currentPath? true : false;
    }    

    return (        
        <React.Fragment>
            <ListItemButton component={Link} to={path} selected={activeRoute(path, location.pathname)}>
                <ListItemIcon>
                    <settings.icon />
                </ListItemIcon>    
                <ListItemText primary={settings.label} />    
            </ListItemButton>        
      </React.Fragment>
    )
}

function SideBarNavContent(props) {
    const { pages } = props
    const { open, toggleDrawer } = useSharedDrawerState()

    return (
        <DrawerStyled variant="permanent" open={open}>
            <Toolbar sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}>
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
                {pages.map(({ path, sidebarnav }) => (
                    sidebarnav 
                    ? 
                    MenuItems(path, sidebarnav)        
                    :                     
                    ''
                ))}
            </List>
        </DrawerStyled>
    )
}

export default function SideBarNav(props) {
    const { pages } = props

    return <SideBarNavContent pages={pages}/>
}

export function SideBarNavState() {
    return useSharedDrawerState ()
}

export const Width = drawerWidth