import React from 'react'
import ReactDOM from 'react-dom'

import './simpleUI/index.css'

import {
    SimpleUIApp,
} from './simpleUI'

import PalletStackCalculator from "./pages/palletstackcalculator"
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'

import Calc from "./pages/calc"
import CalculateIcon from '@mui/icons-material/Calculate';

const settings = {
    useAuthentication: false
}

const pages = [
    {
        path: "/",
        Main: PalletStackCalculator,
        sidebarnav: {
            icon: PrecisionManufacturingIcon,
            label: "Stack Calc."
        }
    },
    {
        path: "/calc",
        Main: Calc,
        sidebarnav: {
            icon: CalculateIcon,
            label: "Calc."
        }
    }
]

ReactDOM.render(
    <React.StrictMode>
        <SimpleUIApp pages={pages} settings={settings}/>        
    </React.StrictMode>,
    document.getElementById('root')
)