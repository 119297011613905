import SimpleUIApp from "./components/app.js"

import SimpleUIAppBar from "./components/appbar"
import SimpleUISideBarNav from "./components/sidebarnav"

import SimpleUISignIn from "./components/signin"
import SimpleUIForgotPassword from "./components/forgotpassword"
import SimpleUIResetPassword from "./components/resetpassword"

import SimpleUILogger from "./components/logger"


// Dashboard
// import Dashboard from "./pages/dashboard"
// import DashboardIcon from '@mui/icons-material/Dashboard'

// Users
import Users from "./pages/users"
import UsersIcon from '@mui/icons-material/People'


const SimpleUIPageUsers = {
    path: "/users",
    Main: Users,
    sidebarnav: {
        icon: UsersIcon,
        label: "Users"
    }
}

export { 
    // App
    SimpleUIApp,

    // APPUI
    SimpleUIAppBar, 
    SimpleUISideBarNav,

    // SIGNIN
    SimpleUISignIn,
    SimpleUIForgotPassword,
    SimpleUIResetPassword,

    // LOGGER
    SimpleUILogger,

    // PAGES
    SimpleUIPageUsers,
}