import * as React from 'react';
import { useState, useEffect } from 'react'

import {
    CssBaseline,

    Box,
    Paper,
    Grid,
    Collapse,
    
    Typography,

    Alert,
    Avatar, 
    Link,

    // FormControlLabel,
    // Checkbox,
    Button,
    TextField,
    IconButton,
} from '@mui/material/'

import {LoadingButton} from '@mui/lab/'

import {
    Close as CloseIcon,
    LockOutlined as LockOutlinedIcon
} from '@mui/icons-material/'

import { authSignIn, useAuthState, useAuthDispatch } from './authProvider';

export default function SignInSide() {    
    const [form, setForm] = useState({
        disabled: false,
        loading: false,
        mainButtonDisabled: true,
        error: false,    
        errorText: "",
    })    

    const dispatch = useAuthDispatch()
	// const { loading, errorMessage } = useAuthState()

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        setForm({ ...form, loading: true, error: false, disabled: true })

        try {
            await authSignIn(dispatch, {username: data.get('username'), password: data.get('password')})            
        } catch (error) {
            handleError(error)
        }
    }

    const handleError = (error) => {        
        setForm({ ...form, loading: false, error: false, disabled: false })

        if (error.message == "ER_INVALID_USERNAME_PASSWORD")
            setForm({ ...form, error: true, errorText: "Username or Password does not match." })
        else
            setForm({ ...form, error: true, errorText: "An unknown error occurred." })        
    }
    
    const onChange = (event) => {
        const data = new FormData(event.currentTarget)

        if (data.get('username').length > 0 && data.get('password').length > 0)
            setForm({ ...form, mainButtonDisabled: false })
        else
            setForm({ ...form, mainButtonDisabled: true })            
    }

    return (    
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(https://source.unsplash.com/random/?wallpapers)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>               
                    <Box component="form" noValidate onSubmit={handleSubmit} onChange={onChange} sx={{ mt: 1 }}>
                        <Collapse in={form.error}>
                            <Alert variant="filled" severity="error" 
                                action={
                                    <IconButton aria-label="close" color="inherit" size="small"
                                        onClick={() => {
                                            setForm({ ...form, error: false })
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                {form.errorText}
                            </Alert>
                        </Collapse>
                        <TextField                            
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus                            
                            disabled={form.disabled}                      
                        />
                        <TextField                            
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            disabled={form.disabled}                      
                        />
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <LoadingButton 
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={form.mainButtonDisabled || form.disabled}
                            loading={form.loading}  
                            >
                                Sign In
                        </LoadingButton>                           
                        <Grid container>
                            <Grid item xs>
                                <Link href="/forgotpassword" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>                                
                            </Grid>
                        </Grid>                        
                    </Box>
                </Box>
            </Grid>
        </Grid>    
    )
}