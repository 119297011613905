import * as React from 'react';
import { useState, useEffect } from 'react'

import {
    CssBaseline,

    Box,
    Paper,
    Grid,
    Collapse,
    
    Typography,

    Alert,
    Avatar, 
    Link,

    // FormControlLabel,
    // Checkbox,
    Button,
    TextField,
    IconButton,
} from '@mui/material/'

import {LoadingButton} from '@mui/lab/'

import {
    Close as CloseIcon,
    LockOutlined as LockOutlinedIcon
} from '@mui/icons-material/'

import { signIn, useAuthState, useAuthDispatch } from './authProvider';

export default function ForgotPassword() {    
    const [form, setForm] = useState({
        success: false,
        disabled: false,
        loading: false,
        mainButtonDisabled: true,
        error: false,    
        errorText: "",
    })    

    const dispatch = useAuthDispatch()	

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        setForm({ ...form, loading: true, error: false, disabled: true })

        fetch('/api/auth/forgotpassword', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',                
            },
            body: JSON.stringify({
                email: data.get("email"),                
            }),
        })
        .then((data) => {
            if (data.ok) {                
                setForm({ ...form, success: true })
            } else {
                return data.json ()            
            }
        })        
        .then((data) => {
            if (data != null) {
                if (data.error != null)
                    throw new Error (data.error.code)                
                else 
                    throw new Error ("ER_UNKNOWN")
            }
        })
        .catch((error) => {                
            handleError (error)
        })        
    }

    const handleError = (error) => {        
        setForm({ ...form, loading: false, error: false, disabled: false })

        if (error.message == "ER_INVALID_USERNAME_PASSWORD")
            setForm({ ...form, error: true, errorText: "Username or Password does not match." })
        else
            setForm({ ...form, error: true, errorText: "An unknown error occurred." })        
    }
    
    const onChange = (event) => {
        const data = new FormData(event.currentTarget)

        if (data.get('email').length > 0 && data.get('email').length > 0)
            setForm({ ...form, mainButtonDisabled: false })
        else
            setForm({ ...form, mainButtonDisabled: true })            
    }

    return (    
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(https://source.unsplash.com/random/?wallpapers)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >                    

                    <Collapse in={form.success}>
                        <Typography component="h1" variant="h5">
                            Password reset
                        </Typography>      
                        <Typography variant="body1" paragraph>
                            If the specified email addresse is connected to a valid user, an email will be send containing a link to reset your password.
                        </Typography>                        
                        <Typography variant="body1" paragraph>
                            If you do not receive this email within 5 minutes, and you are sure it belongs to a valid user. Check your spam folder. 
                        </Typography>                        
                        <Button href="/" variant="contained">Return to login</Button>       
                    </Collapse>

                    <Collapse in={!form.success}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot password
                    </Typography>      
                    <Box component="form" noValidate onSubmit={handleSubmit} onChange={onChange} sx={{ mt: 1 }}>
                        <Collapse in={form.error}>
                            <Alert variant="filled" severity="error" 
                                action={
                                    <IconButton aria-label="close" color="inherit" size="small"
                                        onClick={() => {
                                            setForm({ ...form, error: false })
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                {form.errorText}
                            </Alert>
                        </Collapse>
                        <TextField                            
                            margin="normal"
                            required
                            fullWidth                            
                            label="Email"
                            name="email"                            
                            autoFocus                            
                            disabled={form.disabled}                      
                        />                        
                        <LoadingButton 
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={form.mainButtonDisabled || form.disabled}
                            loading={form.loading}  
                            >
                                Request reset
                        </LoadingButton>                                                   
                    </Box>
                    </Collapse>
                </Box>
            </Grid>
        </Grid>    
    )
}