import * as React from 'react'
import { useState, useEffect } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

import DialogTitle from '@mui/material/DialogTitle'

const ConfirmDialog = (props) => {
    const {open, dialogState, setDialogState } = props

    const handleAccept = () => {
        setDialogState({ ...dialogState, open: false })
        
        if (dialogState.onConfirm != null)
            dialogState.onConfirm ()        
    }
    
    const handleDecline = () => {        
        setDialogState({ ...dialogState, open: false })
        
        if (dialogState.onDecline != null)
            dialogState.onDecline ()        
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return   

        handleDecline()
    }
    
    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {dialogState.title}
            </DialogTitle>
            <DialogContent>
                {dialogState.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDecline}>{dialogState.button1Text}</Button>
                <Button onClick={handleAccept} autoFocus>{dialogState.button2Text}</Button>
            </DialogActions>
        </Dialog>
    )
}
  
export default ConfirmDialog