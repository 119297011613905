import * as React from 'react';
import { useState, useEffect, useRef } from 'react'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

function calculatePallet (stackCount, minStackHeight, maxStackHeight, containerSize, itemCount ) {        
    const itemsInStackLayer = (stackCount * containerSize)                      // Calculate items in stack layer.
    const minItemsOnPallet = ((stackCount * containerSize) * minStackHeight)    // Calculate minimum items allowed on a pallet.
    const maxItemsOnPallet = ((stackCount * containerSize) * maxStackHeight)    // Calculate maximum items allowed on a pallet. 

    
    console.log ("Items in stack layer: "+ itemsInStackLayer)
    console.log ("Min. items on a pallet: "+ minItemsOnPallet)
    console.log ("Max. items on a pallet: "+ maxItemsOnPallet)

    console.log ("Items: "+ itemCount)
    
    let palletsNeeded = Math.ceil(itemCount / maxItemsOnPallet)

    console.log ("Total pallets needed: "+ palletsNeeded)

    // if (maxItemsOnPallet * (Math.floor(itemCount / maxItemsOnPallet) < itemCount)) {
    // 
    // }

    let pallets = []

    for (let index = 0; index < palletsNeeded; index++) {
        
        if ((index+1)*maxItemsOnPallet < itemCount) {
            pallets.push ({
                pallet: (index+1),
                totalItems: maxItemsOnPallet,
                stackHeight: maxStackHeight,
                emptyContainers: 0
            })
        } else
        {
            let totalItems = itemCount - (index)*maxItemsOnPallet
            let stackHeight = Math.ceil(totalItems / itemsInStackLayer)

            if (stackHeight < minStackHeight) {
                let neededStacks = minStackHeight - stackHeight

                pallets[(index-1)].stackHeight = (pallets[(index-1)].stackHeight - neededStacks)
                pallets[(index-1)].totalItems = (pallets[(index-1)].totalItems - (neededStacks*itemsInStackLayer))

                totalItems += (neededStacks*itemsInStackLayer)
                stackHeight += neededStacks                
            }

            let emptyContainers = Math.floor((((stackHeight*containerSize)*stackCount)-totalItems)/containerSize)       // Calculate empty containers.

            if (emptyContainers >= stackCount) {
                let c1 = Math.ceil(emptyContainers/stackCount)
                
                // stackHeight =  stackHeight - c1
                // console.log (c1)
                // emptyContainers = Math.ceil((((stackHeight*containerSize)*stackCount)-totalItems)/stackCount)       // Calculate empty containers.
            }


            pallets.push ({
                pallet: (index+1),
                totalItems: totalItems,
                stackHeight: stackHeight,
                emptyContainers: emptyContainers
            })
        }
    }

    return pallets

    // pallets.forEach(element => {
    //     console.log (element)
    // })

    // console.log ("\t"+ "Pallet #"+ (index+1) +":")

    // console.log ("----------")

    // if (Math.floor(itemCount / maxItemsOnPallet) == 0) {

    //     console.log ("One pallet is needed")
    //     if (itemCount < minItemsOnPallet) {
    //         console.log ("There are less items than the min. allowed item count for a pallet.")
    //     }         
    // }

    // if (Math.floor(itemCount / maxItemsOnPallet) >= 1) {
    //     console.log (Math.floor(itemCount / maxItemsOnPallet) +" full pallet(s) needed.")

        
    //     if (maxItemsOnPallet * (Math.floor(itemCount / maxItemsOnPallet) < itemCount)) {

    //         let rest = itemCount - (maxItemsOnPallet * (Math.floor(itemCount / maxItemsOnPallet)))

    //         console.log (rest)

    //         console.log (rest) 

    //         // console.log (itemCount * )) 
    //     }


    // }
    
    



    
}


function PalletDisplay(props) {
    const { pallet } = props

    return (
        <Paper elevation={3} sx={{ p: 2, m: 1, width: {xs: "100%", md: 150}, height: 128 }}>
            <Typography variant="body1" gutterBottom>
                <b>Pallet #{pallet.pallet}</b>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                Stack height: {pallet.stackHeight}<br/>
                Empty containers: {pallet.emptyContainers}<br/>
                Total items: {pallet.totalItems}<br/>
            </Typography>
        </Paper>
    )
}

function PalletStackCalculator() {
    const formDefaultValues = {
        productionLineId: "",
        productId: "",
        productionCount: 0,   
        stackCount: 0,
        containerSize: 0,
        minStackHeight: 0,
        maxStackHeight: 0,     
        pallets: [],                        
    }

    const productionLines = [
        {
            id: "jupiter",
            title: "Jupiter"
        },
        {
            id: "mars",
            title: "Mars"
        },
    ]

    const products = [
        {
            id: "mars-front",
            productionLineId: "mars",            
            title: "Front",   
            stackCount: 6,
            minStackHeight: 5,
            maxStackHeight: 24,
            containerSize: 36,
        },
        {
            id: "mars-rear",
            productionLineId: "mars",
            title: "Rear",
            stackCount: 6,    
            minStackHeight: 5,        
            maxStackHeight: 32,
            containerSize: 60,
        },
        {
            id: "jupiter-front",
            productionLineId: "jupiter",
            title: "Front",   
            stackCount: 6,
            minStackHeight: 5,         
            maxStackHeight: 21,
            containerSize: 24,
        },
        {
            id: "jupiter-rear",
            productionLineId: "jupiter",
            title: "Rear",
            stackCount: 6,    
            minStackHeight: 5,        
            maxStackHeight: 32,
            containerSize: 36,
        },
    ]

    const formDefault = {
        disabled: false,        
        productIdDisabled: true,  
        productCountDisabled: true
    }
                
    const [data, setData] = useState(formDefaultValues)    

    const [optionsProductionLine, setOptionsProductLine] = useState(productionLines)
    const [optionsProduct, setOptionsProduct] = useState([])    

    const [form, setForm] = useState(formDefault)
    
    const onChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value
                
        let formState = {...form}
        let data1 = {...data}
        
        data1[id] = value

        console.log (id)

        if (id == "productionLineId") {            
            data1.productId = ""
            data1.stackCount = 0
            data1.containerSize = 0
            data1.minStackHeight = 0
            data1.maxStackHeight = 0
            
            let options = []
            products.forEach(element => {
                if (element.productionLineId == data1.productionLineId) {
                    options.push(element)
                }
            })

            setOptionsProduct(options)
            formState.productIdDisabled = false
        }

        if (id == "productId") {
            let product = products.find (o => o.id === value)
            data1.productId = product.id
            data1.stackCount = product.stackCount
            data1.containerSize = product.containerSize
            data1.minStackHeight = product.minStackHeight
            data1.maxStackHeight = product.maxStackHeight
            formState.productCountDisabled = false
        }
            
        if (data1.productionCount > 500 && data1.productId != "") {
            let product = products.find (o => o.id === data1.productId)        
            data1.pallets = calculatePallet(product.stackCount, product.minStackHeight, product.maxStackHeight, product.containerSize, data1.productionCount)
        } else {
            data1.pallets = []
        }
        
        

        setForm(formState)
        setData(data1)
                
        // console.log ("onchange:"+ id +":"+ value)
        return true
    }
    return (
        
        <React.Fragment>            
            <React.Fragment>               
                <Paper elevation={3} sx={{ p: 2 }}>
                    <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                        Pallet stack calculator
                    </Typography>

                    <FormControl  
                        required
                        margin="dense" 
                        fullWidth 
                        variant="standard"                                 
                        disabled={form.disabled}
                    >
                        <InputLabel>Production line</InputLabel>
                        <Select name="productionLineId" value={data.productionLineId} onChange={onChange}>
                            {optionsProductionLine.map((option, index) => (
                                <MenuItem value={option.id}>{option.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl  
                        required
                        margin="dense" 
                        fullWidth 
                        variant="standard"                                 
                        disabled={form.productIdDisabled || form.disabled}
                    >
                        <InputLabel>Product</InputLabel>
                        <Select name="productId" value={data.productId} onChange={onChange}>
                            {optionsProduct.map((option, index) => (
                                <MenuItem value={option.id}>{option.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        required                    
                        margin="dense"
                        id="productionCount"
                        label="Production count"
                        fullWidth
                        type="number"             
                        autoComplete="off"       
                        variant="standard"                    
                        onChange={onChange}                        
                        error={form.clientIdError}
                        helperText={form.clientIdErrorText}                   
                        disabled={form.productCountDisabled | form.disabled}
                    >         
                    </TextField>

                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} md={4}>
                            <Paper elevation={3} sx={{ m: 1, p: 2, height: 128 }}>
                                <Typography variant="body1" gutterBottom>
                                    {/* <b>Stacks:</b> {data.stackCount}<br/> */}
                                    <b>Container size:</b> {data.containerSize}<br/>                                
                                    <b>Min. stack height:</b> {data.minStackHeight}<br/>
                                    <b>Max. stack height:</b> {data.maxStackHeight}<br/>                                
                                </Typography>
                            </Paper>                        
                        </Grid>                        
                        <Grid item xs={12} md={8}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}                                
                                >
                                {data.pallets.map((pallet, index) => (
                                    <PalletDisplay pallet={pallet}/> 
                                ))}
                            </Box>
                        </Grid>
                    </Grid> 

                </Paper>                                                                
            </React.Fragment>
        </React.Fragment>     
    )
}

function main() {
    return (        
        <Grid container sx={ { height: '100%' }}>
            <Grid item xs={12}>
                <PalletStackCalculator />
            </Grid>            
        </Grid>            
    )
}

export default main
